import React, { useEffect, useState } from 'react';
import lefthead from '../Assets/genesis/lefthead.png';
import righthead from '../Assets/genesis/righthead.png';
import logo from "../Assets/genesis/footerlogo.png";
import { Link } from 'react-router-dom';

import twitter from '../Assets/genesis/twitter.svg';
import telegram from '../Assets/genesis/telegram.svg';
import whatsapp from '../Assets/genesis/whatsapp.svg';

const Footer = () => {

    return (

        <div className=' footers pt-5 pb-5'>
           <div className='container container-theme'>
           <div className='alltitle text-center'><img src={lefthead} className='lefthead' alt='' /><img src={righthead} className='righthead' alt='' /><img src={logo} className='img-fluid logoss' alt='' /> </div>
      
           {/* <div className='row justify-content-center  mt-2 mb-3'>
            <div className='col-sm-6 col-md-4 text-center'>
                <ul>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/">Developers</Link></li>
                    <li><Link to="/">Community</Link></li>
                </ul>
            </div>
            <div className='col-sm-6 col-md-4 text-center'>
                <ul>
                    <li><Link to="/">Examples</Link></li>
                    <li><Link to="/">Bridges</Link></li>
                    <li><Link to="/">OpenGPT Scan</Link></li>
                </ul>
            </div>
            <div className='col-sm-6 col-md-4 text-center'>
                <ul>
                    <li><Link to="/">Core Features</Link></li>
                    <li><Link to="/">Guides</Link></li>
                    <li><Link to="/">Troubleshooting</Link></li>
                </ul>
            </div>
            </div>
            <div className='text-center pt-3 pb-3'><button className='btn userbtn headingbtn px-5'>Developer Assistance</button></div>
      
            <div className='socialmedias text-center pt-3 pb-3'>
               <a href='https://twitter.com/' target='_blank'><img src={twitter} className='twitter' alt='twitter' /></a>
               <a href='https://telegram.org/' target='_blank'><img src={telegram} className='telegram' alt='telegram' /></a>
               <a href='https://www.whatsapp.com/' target='_blank'><img src={whatsapp} className='whatsapp' alt='whatsapp' /></a>
            </div>

            <div className='terms text-center pt-3 pb-3'>
               <a href='/'>Privacy Policy</a>
               <a href='/'>Terms & Conditions</a>
               <a href='/'>About</a>
            </div> */}

            <div><p className='copyright text-center mt-4  mb-0'>Copy rights @2024.All Rights Reserved</p></div>

           </div>
        </div>
    )
}

export default Footer;