
export const getAccount = () => {
    // return localStorage.getItem("accountInfo")? localStorage.getItem("accountInfo"): null;
    return localStorage.getItem("accountInfo")? localStorage.getItem("accountInfo"): null;
}
export const setChainId = (i) =>{
  
    localStorage.setItem("CHAIN",i);
    window.location.reload();
}
export const getChainId = ()=>{
   
    const index = localStorage.getItem("CHAIN");
    return index!=null ? index : "0";
}


