import React, { useState, useEffect, useRef } from 'react';
import { Tab, Tabs, Accordion, Button, Offcanvas } from 'react-bootstrap';
import { GetCms } from '../../Hooks/UseBackend'


const Codedetail = (props) => {
    const [cmsdata, setCmsdata] = useState({});
    const [codedetail, setCodedetail] = useState(true)
    const CmsData = async () => {
        const result = await GetCms()
        
        if (result) {
            setCmsdata(result)
        }

    }
    useEffect(() => {
        CmsData()
    }, [])
    return (

        <>

            <div className='viewcanvas'>
                <Offcanvas show={codedetail} placement='end' name="end">
                    <Offcanvas.Header>
                        <h3 className='fw-600'>PingPong.sol</h3>
                        <button className='btn btn-link themeclr close' onClick={() => props.onDismiss()}><span className='fa fa-times'></span></button>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        {`${cmsdata[11]?.identifier == "content3" ? cmsdata[11]?.content : <></>}`}
                        <br></br><br></br>



                    </Offcanvas.Body>
                </Offcanvas>
            </div>


        </>
    )
}

export default Codedetail;
