import axios from "axios";
import toast, { Toaster } from 'react-hot-toast';
import {style,iconTheme,position} from './useToast'
import * as config from "../Config/env";
import { sleep } from "./useContract";

export const getLaunchpadData = async() => {
      const Contactinfo =  await   axios.get(`${config.backurl}/api/get-LaunchPad-data`);
     
      return Contactinfo.data;
     }


  
     export const getAboutData = async() => {
      const Contactinfo =  await   axios.get(`${config.backurl}/api/get/about`);
     
      return Contactinfo.data;
     }
    
     export const getFeatureData = async() => {
      const Contactinfo =  await   axios.get(`${config.backurl}/api/get/Feature`);
     
      return Contactinfo.data;
     }
     export const getFeaturedesData = async() => {
      const Contactinfo =  await   axios.get(`${config.backurl}/api/get/featuredes`);
     
      return Contactinfo.data;
     } 
     
     export const getRoadmapdesData = async() => {
      const Contactinfo =  await   axios.get(`${config.backurl}/api/get/Roadmapdes`);
     
      return Contactinfo.data;
     }  


     export const getSettingsData = async() => {
      const Contactinfo =  await   axios.get(`${config.backurl}/api/get/setting`);
     
      return Contactinfo.data;
     } 
    
      
     export const getNewsData = async() => {
      const Contactinfo =  await   axios.get(`${config.backurl}/api/get/news`);
     
      return Contactinfo.data;
     }  

       
     export const getArticles = async() => {
      const Contactinfo =  await   axios.get(`${config.backurl}/api/get/articles`);
      return Contactinfo.data;
     }  

     

        
     export const getNewsDesData = async() => {
      const Contactinfo =  await   axios.get(`${config.backurl}/api/get/newsdespreference`);
     
      return Contactinfo.data;
     }  


          
     export const getNewsDesDataLimit = async() => {
      const Contactinfo =  await   axios.get(`${config.backurl}/api/get/newsdeslimit`);
     
      return Contactinfo.data;
     }  


     export const getNewsDesDataOnedata = async(id) => {
   
      const userinfo =  await   axios.get(`${config.backurl}/api/get/onenewsdes/${id}`)
            return userinfo.data;
   }


   export const getOneArticlesdata = async(id) => {
   
      const userinfo =  await   axios.get(`${config.backurl}/api/get/articles/${id}`)
            return userinfo.data;
   }

  

   export const UseContact = (param) => {


      axios
          .post(config.backurl + "/api/add/Contactus", param)
          .then(async (res) => {
  
              if (res.status === 200 && res.data.success === true) {
  
                  toast.success(`${res.data.data}`, {
                     position:position.position,
                      style:style,
                      iconTheme: iconTheme,
                  })
                  await sleep(5000)
                  // window.location.href = config.frontendurl ;
  
              } else {
                  toast.success(`${res.data.data}`, {
                     position:position.position,
                      style:style,
                      iconTheme: iconTheme,
                  }
                  )
              }
          }
          ).catch(err => {
          }
          );
  
  };

  export const getArticle1Data = async() => {
    const Contactinfo =  await   axios.get(`${config.backurl}/api/get/aimage1`);
   
    return Contactinfo.data;
   }  

   export const getArticle2Data = async() => {
    const Contactinfo =  await   axios.get(`${config.backurl}/api/get/aimage2`);
   
    return Contactinfo.data;
   }  

   export const getArticle3Data = async() => {
    const Contactinfo =  await   axios.get(`${config.backurl}/api/get/aimage3`);
   
    return Contactinfo.data;
   }  

   export const getArticle4Data = async() => {
    const Contactinfo =  await   axios.get(`${config.backurl}/api/get/aimage4`);
   
    return Contactinfo.data;
   } 
   
   export const getTotalArticleImage = async() => {
    const Contactinfo =  await   axios.get(`${config.backurl}/api/get/Totalaimage`);
   
    return Contactinfo.data;
   } 


export const GetAllCmsData = async () => {
    const CmsInfo = await  axios.get(`${config.backurl}/api/get/AllCmsData`);
    console.log("CmsInfodata", CmsInfo)
    return CmsInfo.data
}



export const    GetCms = async() => {
    const cmsdata =  await   axios.get(`${config.backurl}/api/get-cms-data`);
   console.log("cmsdatacmsdata",cmsdata);
    return cmsdata.data;
   }

   
   export const    imageCmsData = async() => {
    const imageCmsData =  await   axios.get(`${config.backurl}/api/get-image-cms`);
   console.log("imageCmsData",imageCmsData);
    return imageCmsData.data;
   }



   export const    terminalcmsData = async() => {
    const terminalcmsData =  await   axios.get(`${config.backurl}/api/get-terminal-cms`);
   console.log("terminalcmsData",terminalcmsData);
    return terminalcmsData.data;
   }

   