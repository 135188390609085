

// const env_name = "local"

const env_name = "live"
var backurl = ""
if(env_name == "local"){
    backurl = "http://localhost:9374"
}
else{
    backurl ="https://api.genesis-pt.org" //live
    // backurl = "https://backend-genesis.maticz.in" // demo
  
}


export default backurl;